// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ankh-js": () => import("./../../../src/pages/ankh.js" /* webpackChunkName: "component---src-pages-ankh-js" */),
  "component---src-pages-firefly-js": () => import("./../../../src/pages/firefly.js" /* webpackChunkName: "component---src-pages-firefly-js" */),
  "component---src-pages-heart-js": () => import("./../../../src/pages/heart.js" /* webpackChunkName: "component---src-pages-heart-js" */),
  "component---src-pages-hummingbird-js": () => import("./../../../src/pages/hummingbird.js" /* webpackChunkName: "component---src-pages-hummingbird-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leaf-js": () => import("./../../../src/pages/leaf.js" /* webpackChunkName: "component---src-pages-leaf-js" */),
  "component---src-pages-spaceship-js": () => import("./../../../src/pages/spaceship.js" /* webpackChunkName: "component---src-pages-spaceship-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-single-page-js": () => import("./../../../src/templates/single/Page.js" /* webpackChunkName: "component---src-templates-single-page-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/single/Post.js" /* webpackChunkName: "component---src-templates-single-post-js" */)
}

